<template>
  <AView
    :value="value"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
  >
    <template v-slot:content="{}">
      <MessageBoxVue class="pa-4" @onMessage="onMessage"></MessageBoxVue>
      <LeadTimelineVue :timeline="history"></LeadTimelineVue>
    </template>
  </AView>
</template>

  
  
  
  
  <script>
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import MessageBoxVue from "../../../../../components/wad/molecules/common/MessageBox.vue";
import LeadTimelineVue from "../../../../../components/wad/molecules/sales/LeadTimeline.vue";
import { mapState } from "vuex";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      history: [
        { id: 1, message: "Lead was updated", time: new Date().toISOString() },
        { id: 2, message: "Lead was created", time: new Date().toISOString() },
      ],
    };
  },
  components: {
    AView,
    MessageBoxVue,
    LeadTimelineVue,
  },
  computed: mapState("LeadStore", ["lead"]),

  methods: {
    onMessage(message) {
      this.history.push(message);
    },
    onHover() {},
  },
};
</script>
  
  
  <style lang="scss" scoped>
</style>